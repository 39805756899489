.app__navbar {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1rem 2rem;
    background: var(--white-color);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: none;
    position: fixed;
    z-index: 2;
    box-shadow: 0 0 40px rgba(0, 0, 0, 1);
}

.app__navbar-logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
        width: 50px;
        height: 50px;
    }

    h2 {
        font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 2rem;
    }
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 50px;
    list-style:  none;

    li {
        margin: 0 1rem;
        cursor: pointer;

        flex-direction: column;

        //dot
        div {
            width: 30px;
            height: 2px;
            background: transparent;
            border-radius: 5px;

            margin-bottom: 2px;
        }

        a {
            color: var(--black-color);
            text-decoration: none;
            flex-direction: column;

            //text-transform: uppercase;
            font-weight: 600;

            transition: all 0.3s ease-in-out;

            &:hover {
                color: var(--black-color);
            }
        }

        &:hover {
            div {
                background: var(--black-color);
                transition: 2s;
            }
        }
    }

    @media screen and (max-width: 900px) {
        display: none;
    }
}

.app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--black-color);

    svg {
        width: 70%;
        height: 70%;
        color: var(--white-color);

        &:hover {
            opacity: 50%;
            transition: 0.5s;
        }
    }

    div {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;

        padding: 1rem;

        width: 80%;
        height: 100vh;

        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;

        background: rul('../../ assets/bgWhite.png');
        background-color: var(--primary-color);
        background-size: cover;
        background-repeat: repeat;

        box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

        svg {
            width: 35px;
            height: 35px;
            color: var(--black-color);
            margin: 0.5rem 1rem;

            &:hover {
                opacity: 50%;
                transition: 0.5s;
            }
        }

        ul {
            text-transform: uppercase;

            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;

            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
        }

        li {
            margin: 1rem;

            a {
                color: var(--gray-color);
                text-decoration: none;
                font-size: 1rem;
                text-transform: upperccase;
                font-weight: 700;

                transition: all 0.3s ease-in-out;

                &:hover {
                    color: var(--secondary-color);
                }
            }
        }

        @media screen and (min-width: 900px) {
            display: none;
        }
    }

    @media screen and (min-width: 900px) {
        display: none;
    }
}