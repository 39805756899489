#singlework {
    padding: 10rem 7rem 5rem 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--primary-color);

    @media screen and (max-width: 600px) {
      padding: 10rem 3rem 5rem 3rem;
    }
  }

  .work-content {
    padding: 2rem 0rem 5rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;

    max-width: 1000px;

    img {
      max-width: 50%;
      // border: 2px solid var(--black-color);
      // border-radius: 10px;
      padding: 0rem 0rem 1.5rem 0rem;
      align-self: center;

      @media screen and (max-width: 600px) {
        max-width: 80%;
      }
    }

    p {
      padding: 0rem 0rem 1.5rem 0rem;
      font-size: 1rem;
      text-align: left;
      line-height: 2;
    }

    h1, h2, h3, h4, h5, h6 {
      padding: 0rem 0rem 1.5rem 0rem;
    }
  }

  .single-work__back-button {
    padding: 1rem 2rem;
    border: 1px solid var(--black-color);
    border-radius: 10px;
    background-color: var(--primary-color);

    font-weight: 500;
    color: var(--primaryText-color);
    outline: none;
    margin: 2rem 0 0 0;
    font-family: var(--font-base);

    transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    cursor: pointer;

    &:hover {
      background-color: var(--black-color);
      color: var(--white-color);
      transition: 1s;
      p {
        color: var(--white-color);
        transition: 1s;
      }
    }
  }