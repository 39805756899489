:root {
  --font-base: "Roboto", sans-serif;

  --primary-color: #ffffff;
  --secondary-color: #ffffff;
  --black-color: #000000;
  --gray-color: #5a5a5a;
  --white-color: #ffffff;
  --headerGradient-color: linear-gradient(to right, #FDFBFB, #EBEDEE 70%);
  
  --primaryText-color: #000000;
  --secondaryText-color: #222222;
  --tertieryText-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}