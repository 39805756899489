#footer {
    padding: 3rem 1rem 3rem 1rem;
    display: flex;
    background: var(--black-color);
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .footer__email {
        color: var(--white-color);
        justify-content: center;
        align-items: center;
        text-align: center;
        text-decoration: none;

        font-size: small;

        &:hover {
          opacity: 30%;
          transition: 1s;
        }
    }
}

.app__footer-icons {
  color: var(--white-color);
  padding: 1rem 1rem 1rem 1rem;

  a {
    color: var(--white-color);
    padding: 0rem 1rem 0rem 1rem;

    &:hover {
      opacity: 30%;
      transition: 1s;
    }
  }
}

.footer__end {
    font-weight: 600;
    //font-style: italic;

    span {
      color: turquoise;
    }
}