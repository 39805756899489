#contact {
    padding: 7rem 0rem 5rem 0rem;
    flex-direction: column;
    align-items: center;
}

.app__footer {
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.app__footer-form {
  width: 60%;
  flex-direction: column;
  margin: 1rem 2rem;
  padding: 0rem 4rem 0rem 4rem;

  div {
    width: 100%;
    margin: 0.75rem 0;
    border-radius: 10px;
    border: 1px solid var(--black-color);
    cursor: pointer;
    background-color: var(--primary-color);
    padding: 0.05rem;
    transition: all 0.3s ease-in-out;

    input,
    textarea {
      width: 100%;
      padding: 0.95rem;
      border-radius: 7px;
      border: none;
      background-color: var(--primary-color);

      font-family: var(--font-base);
      color: var(--primaryText-color);
      outline: none;
    }

    textarea {
      height: 170px;
    }

    &:hover {
      box-shadow: 0 0 25px var(--black-color);
    }
  }

  button {
    padding: 1rem 2rem;
    border-radius: 10px;
    border: 1px solid var(--black-color);
    background-color: var(--primary-color);

    font-weight: 500;
    color: var(--primaryText-color);
    outline: none;
    margin: 2rem 0 0 0;
    font-family: var(--font-base);

    transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    cursor: pointer;

    &:hover {
      background-color: var(--black-color);
      color: var(--white-color);
      transition: 1s;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
}

.app__contact-thanks {
  padding: 2rem 2rem 0rem 2rem;
}