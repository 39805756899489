#psi {
    padding: 10rem 7rem 5rem 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--white-color);

    height: auto;
    min-height: 100vh;
    width: 100%;
}
  