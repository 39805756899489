.app {
    background-color: var(--primary-color);
    font-family: var(--font-base);
    overflow-x: hidden;
  }
  
  .app__whitebg {
    background-color: var(--white-color);
  }
  
  .app__primarybg {
    background-color: var(--primary-color);
  }
  
  .app__container {
    width: 100%;
    min-height: 100vh;
  
    display: flex;
    flex-direction: row;
  }
  
  .app__flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .app__wrapper {
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 4rem 2rem;
  
    @media screen and (max-width: 450px) {
      padding: 4rem 1rem 2rem;
    }
  }
  
  .copyright {
    width: 100%;
    padding: 2rem 0 0;
  
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  
    p {
      text-transform: uppercase;
      color: var(--black-color);
    }
  }
  
  .head-text {
    font-size: 2.75rem;
    font-weight: 700;
    text-align: center;
    color: var(--primaryText-color);
    text-transform: capitalize;
  
    span {
      color: var(--secondaryText-color);
    }
  
    @media screen and (min-width: 2000px) {
      font-size: 4rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 2rem;
    }
  }

  .large-text {
    font-size: 7rem;
    font-weight: 700;
    text-align: center;
    color: var(--primaryText-color);
    text-transform: capitalize;
    margin-top: 5rem;

    @media screen and (max-width: 1200px) {
      font-size: 8rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 5rem;
    }
  }

  .massive-text {
    font-size: 25rem;
    font-weight: 700;
    text-align: center;
    color: var(--primaryText-color);
    text-transform: capitalize;
    margin-top: 5rem;

    @media screen and (max-width: 1200px) {
      font-size: 15rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 7rem;
    }
  }

  .gradient-text {
    background-image: linear-gradient(90deg, blue, red);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .gradient-text-2 {
    background-image: linear-gradient(90deg, rgb(0, 0, 0), rgb(255, 255, 255));
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .p-text {
    font-size: 0.8rem;
    text-align: left;
    color: var(--primaryText-color);
    line-height: 1.5;
  
    // @media screen and (min-width: 2000px) {
    //   font-size: 1.75rem;
    // }
  }

  .p-text-inverted {
    font-size: 0.8rem;
    text-align: left;
    color: var(--white-color);
    line-height: 1.5;
  
    // @media screen and (min-width: 2000px) {
    //   font-size: 1.75rem;
    // }
  }

  .p-text-large {
    padding: 2rem 6rem 0rem 6rem;
    font-size: 1.2rem;
    text-align: center;
    line-height: 2;

    @media screen and (max-width: 600px) {
      padding: 2rem 3rem 0rem 3rem;
    }
  }

  a {
    text-decoration: none;
  }
  
  
  .bold-text {
    //font-size: 1rem;
    font-weight: 700;
    color: var(--primaryText-color);
    text-align: left;
  
    // @media screen and (min-width: 2000px) {
    //   font-size: 2rem;
    // }
  
    @media screen and (max-width: 450px) {
      font-size: 0.9rem;
    }
  }

  .bold-text-inverted {
    font-size: 1rem;
    font-weight: 700;
    color: var(--tertiery-color);
    text-align: left;
  
    @media screen and (min-width: 2000px) {
      font-size: 2rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 0.9rem;
    }
  }
  
  .app__social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
  
    padding: 1rem;
  
    div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--black-color);
      margin: 0.25rem 0;
      border: 1px solid var(--lightGray-color);
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      transition: all 0.3s ease-in-out;
  
      svg {
        width: 15px;
        height: 15px;
        color: var(--gray-color);
      }
  
      &:hover {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
  
        svg {
          color: var(--white-color);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 70px;
        height: 70px;
  
        margin: 0.5rem 0;
  
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  
  .app__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    padding: 1rem;
  
    .app__navigation-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #cbcbcb;
      margin: 0.5rem;
  
      transition: background-color 0.2s ease-in-out;
  
      &:hover {
        background-color: var(--secondary-color);
      }
  
      @media screen and (min-width: 2000px) {
        width: 20px;
        height: 20px;
      }
    }
  }
  
  @media screen and (max-width: 500px) {
    .app__navigation,
    .app__social {
      display: none;
    }
  
    .copyright {
      padding: 2rem;
    }
  }

  .back-button {
    padding: 1rem 2rem;
    border: 1px solid var(--black-color);
    border-radius: 10px;
    background-color: var(--primary-color);

    font-weight: 500;
    color: var(--primaryText-color);
    outline: none;
    margin: 2rem 0 0 0;
    font-family: var(--font-base);

    transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    cursor: pointer;

    &:hover {
      background-color: var(--black-color);
      color: var(--white-color);
      transition: 1s;
      p {
        color: var(--white-color);
        transition: 1s;
      }
    }
  }

  //pages
  .image-oversized {
    display: flex; // Enables centering
    justify-content: center; // Centers the image horizontally
    align-items: center; // Centers vertically (if there's enough height)
    width: 100%;
  
    img {
      max-width: 400%; // Ensures it doesn’t exceed the parent container
      width: auto; // Prevents forced stretching
      height: auto; // Keeps aspect ratio
      object-fit: contain; // Ensures the whole image is visible
    }
  }

  .image-regular {
    display: flex; // Enables centering
    justify-content: center; // Centers the image horizontally
    align-items: center; // Centers vertically (if there's enough height)
    width: 100%;
  
    img {
      max-width: 100%; // Ensures it doesn’t exceed the parent container
      width: auto; // Prevents forced stretching
      height: auto; // Keeps aspect ratio
      object-fit: contain; // Ensures the whole image is visible
    }
  }

  .dual-content-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    height: 100%;
    margin-top: 4rem;
    margin-bottom: 4rem;
    
    .dual-content-block__text {
      flex: 1;
      p {
        color: var(--white-color);
        margin-bottom: 10px;
        font-size: 1rem;
        line-height: 1.5;
        text-align: left;
      }

      h2 {
        color: var(--white-color);
        text-align: left;
        margin-bottom: 0.5rem;
      }
    }
  
    .dual-content-block__image-oversized {
      flex: 1;
      display: flex;
      justify-content: center;
  
      img {
        max-width: 150%;;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
      }
    }

    .dual-content-block__image{
      flex: 1;
      display: flex;
      justify-content: center;
  
      img {
        max-width: 100%;;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
      }
    }

    &--black-text { // Modifier class for black text
      .dual-content-block__text {
        p,
        h2 {
          color: var(--black-color); // Change text to black
        }
      }
    }
  }
  
  // Responsive adjustments
  @media (max-width: 1200px) {
    .dual-content-block {
      flex-direction: column;
      text-align: center;
  
      .dual-content-block__image {
        justify-content: center;
        margin-top: 15px;
      }
    }
  }
  //-------------------------------------------------------

  .video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 3rem;

  iframe {
    max-width: 800px;
    width: 100%;
    height: 450px;
    border-radius: 8px;
  }
}