#home {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10rem 0rem 5rem 0rem;
    position: relative;
    height: auto;
    min-height: 100vh; /* Full viewport height */
    //background: url('../../assets/bgIMG.png');
    background-size: cover;
    background-repeat: repeat;
    background-position: center;

    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }

    //padding: 0rem 0rem 0rem 0rem;
}

.app__header {
    flex: 1;
    width: 100%;
    min-height: 100%;
    height: auto;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 6rem 2rem 0;
    
    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }

    @media screen and (max-width: 400px) {
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        padding-top: 8rem;
        padding-left: 3rem;
        padding-bottom: 5rem;
    }
}

.app__header-badge {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    padding: 0rem 2rem 0rem 0rem;

    .badge-cmp, .tag-cmp {
        padding: 1rem 1rem;
        border: var(--white-color);
        border-radius: 20px;
        flex-direction: row;
        width: auto;
        max-width: 75%; /* Set a maximum width */
        background: var(--primary-color);

        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        position: relative; /* Allow positioning of the tail */
    }

    .tag-cmp{
        flex-direction: column;
        margin-top: 3rem;

        p {
            width: 100%;
            //text-transform: uppercase;
            text-align: left;
        }
    }

    span {
        font-size: 2.5rem;

        @media screen and (min-width: 2000px) {
            font-size: 5rem;
        }
    }

    @media screen and (max-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.app__header-circles {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evently;
    align-items: flex-start;
    width: 100%;
    margin-left: 1rem;

    div {
        width: 100px;
        height: 100px;
        border-radius: 50%;

        background:var(--white-color);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);

        img {
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(1) {
        width: 100px;
        height: 100px;
    }

    div:nth-child(2) {
        margin: 1.75rem;
        width: 150px;
        height: 150px;
    }

    div:nth-child(3) {
        width: 70px;
        height: 70px;
    }

    @media screen and (min-width: 2000px) {
        div:nth-child(1) {
            width: 170px;
            height: 170px;
        }
    
        div:nth-child(2) {
            width: 400px;
            height: 400px;
        }
    
        div:nth-child(3) {
            width: 200px;
            height: 200px;
        }
    }

    @media screen and (max-width: 1200px) {
        /*width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;
        justify-content: center;
    
        div {
            margin: 1rem;
        */
        display: none;
    }
}

.app__header-img {
    flex: 1;
    width:100%;
    height: 100%;

    display: flex;
    justify-content: center; // Center horizontally
    align-items: center; // Center vertically
    position: relative;

    img {
        height: 70%;
        max-height: 60vh;
        z-index:1;
        border-radius: 5%;
    }

    @media screen and (max-width: 1200px) {
         margin: 0rem 0;
    }
}